import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';
import store from './';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'candidates',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/tokens/',
    prepareHeaders,
  }),
  tagTypes: ['candidate'],
  endpoints: builder => ({
    candidates: builder.query({
      query: ({ tokenId, ...params }) => ({
        url: `/${tokenId}/candidates`,
        params,
      }),
    }),
    candidateById: builder.query({
      query: ({ tokenId, id }) => ({
        url: `${tokenId}/candidates/${id}`,
      }),
      providesTags: (_result, _error, { tokenId, id }) => [
        {
          type: 'candidate',
          id: `${tokenId}.${id}`,
        },
      ],
    }),
    candidateDocURL: builder.query({
      query: ({ tokenId, candidateId, docId }) => ({
        url: `${tokenId}/candidates/${candidateId}/documents/${docId}`,
      }),
    }),
    updateKYCStatus: builder.mutation({
      query: ({ tokenId, id, kyc }) => ({
        method: 'POST',
        url: `${tokenId}/candidates/${id}/kyc`,
        body: kyc,
      }),
      invalidatesTags: (_result, _error, { tokenId, id }) => [
        {
          type: 'candidate',
          id: `${tokenId}.${id}`,
        },
      ],
    }),
  }),
});

export default api;

export const {
  useLazyCandidatesQuery,
  useCandidatesQuery,
  useCandidateByIdQuery,
  useLazyCandidateDocURLQuery,
  useUpdateKYCStatusMutation,
} = api;

export const getCandidatesCSVURL = async ({ tokenId }) => {
  const queryString = new URLSearchParams({
    jwt: store.getState().aws.jwt,
  });

  const url = new URL(`${REACT_APP_API_URL_SERVICING}/tokens/${tokenId}/candidates/csv`);
  url.search = queryString;

  return url.toString();
};
