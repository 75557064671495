import { toast } from '@tokenyico/design-system';
import * as Sentry from '@sentry/browser';
import { signOut } from 'app/store/aws';
import history from 'app/history';

export const toastError = async error => {
  console.error(error);
  const title = error.data?.name || error.name || 'Unexpected error';
  const exception =
    error.data?.message || error.message || error.error || error.originalError || error;
  toast.error(title, exception, true);

  Sentry.captureException(`${title}: ${exception}`);

  if (error.status === 403) {
    // unauthorized - sign in again
    signOut();
    history.push('/auth/signin');
  }
};

export const toastSuccess = (message, title = 'Success') => {
  toast.success(title, message);
};
