import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders, makeFormData } from './helpers';
import store from '.';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'marketplaces',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/marketplace-portals/',
    prepareHeaders,
  }),
  tagTypes: ['strapiProject', 'marketplaces', 'marketplaceProjects'],
  endpoints: builder => ({
    strapiProjects: builder.query({
      query: ({ strapiApi, ids }) => `${strapiApi}/projects?id_in=${ids.join('&id_in=')}`,
      providesTags: () => [{ type: 'strapiProject', id: 'all' }],
    }),
    marketplaces: builder.query({
      query: () => '',
      providesTags: () => ['marketplaces'],
    }),
    projects: builder.query({
      query: ({ marketplaceId }) => `${marketplaceId}/projects`,
      providesTags: (_result, _error, { marketplaceId }) => [
        { type: 'marketplaceProjects', id: marketplaceId },
      ],
    }),
    createProject: builder.mutation({
      query: ({ marketplaceId, project }) => ({
        method: 'POST',
        url: `${marketplaceId}/projects`,
        body: makeFormData(project),
      }),
      invalidatesTags: (_result, _error, { marketplaceId }) => [
        { type: 'marketplaceProjects', id: marketplaceId },
        { type: 'strapiProject', id: 'all' },
      ],
    }),
    updateProject: builder.mutation({
      query: ({ marketplaceId, projectId, ...project }) => ({
        method: 'PUT',
        url: `${marketplaceId}/projects/${projectId}`,
        body: makeFormData({
          status: project.status,
          allowedCountries: project.allowedCountries,
          additionalData: project.additionalData,
          title: project.title,
          subtitle: project.subtitle,
          small_description: project.small_description,
          long_description: project.long_description,
          ...(project.header_image instanceof File ? { header_image: project.header_image } : {}),
          investment_details: {
            jurisdiction: project.investment_details.jurisdiction,
            creation_date: project.investment_details.creation_date,
            expiration_date: project.investment_details.expiration_date,
            team: project.investment_details.team,
            documents: project.investment_details.documents,
          },
        }),
      }),
      invalidatesTags: (_result, _error, { marketplaceId }) => [
        { type: 'marketplaceProjects', id: marketplaceId },
        { type: 'strapiProject', id: 'all' },
      ],
    }),
    updateProjectStatus: builder.mutation({
      query: ({ marketplaceId, projectId, status }) => ({
        method: 'POST',
        url: `${marketplaceId}/projects/${projectId}/status`,
        body: {
          status,
        },
      }),
      invalidatesTags: (_result, _error, { marketplaceId }) => [
        { type: 'marketplaceProjects', id: marketplaceId },
      ],
    }),
    deleteProject: builder.mutation({
      query: ({ marketplaceId, projectId }) => ({
        method: 'DELETE',
        url: `${marketplaceId}/projects/${projectId}`,
      }),
      invalidatesTags: (_result, _error, { marketplaceId }) => [
        { type: 'marketplaceProjects', id: marketplaceId },
        { type: 'strapiProject', id: 'all' },
      ],
    }),
  }),
});

export default api;

export const {
  useStrapiProjectsQuery,
  useMarketplacesQuery,
  useProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useUpdateProjectStatusMutation,
  useDeleteProjectMutation,
} = api;

export const useSelectedMarketplace = () => {
  const id = store.getState().selected.selectedMarketplaceId;
  return api.useMarketplacesQuery(undefined, {
    selectFromResult: ({ data, error, ...isStates }) => ({
      ...isStates,
      error,
      selectedMarketplace:
        data?.find(marketplace => marketplace.id === id) ?? (data ? data[0] : undefined),
    }),
  });
};
