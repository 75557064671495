import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders, sanitizeTokens } from './helpers';
import store from '.';
import accounts from './accounts';

const { REACT_APP_API_URL_SERVICING, REACT_APP_ISSUER_PORTAL_ID } = process.env;

const api = createApi({
  reducerPath: 'issuerPortal',
  baseQuery: fetchBaseQuery({
    baseUrl: `${REACT_APP_API_URL_SERVICING}/issuer-portal/${REACT_APP_ISSUER_PORTAL_ID}/`,
    prepareHeaders,
  }),
  tagTypes: ['tokens'],
  endpoints: builder => ({
    tokens: builder.query({
      query: () => '/tokens',
      providesTags: () => [{ type: 'tokens', id: 'all' }],
      transformResponse: response => {
        // this abortion gets the response data from the accountsMe endpoint
        // RTK-Query really doesn't want you reaching into other stores from here
        const account = accounts.endpoints.accountMe.select()(store.getState()).data;

        return sanitizeTokens({ account, response });
      },
    }),

    tokensByNetwork: builder.query({
      query: network => ({
        url: '/tokens/',
        params: { network },
      }),
      providesTags: () => [{ type: 'tokens', id: 'all' }],
      transformResponse: response => {
        // this abortion gets the response data from the accountsMe endpoint
        // RTK-Query really doesn't want you reaching into other stores from here
        const account = accounts.endpoints.accountMe.select()(store.getState()).data;

        return sanitizeTokens({ account, response });
      },
    }),

    issuers: builder.query({
      queryFn: async (_name, _api, _extraOptions, fetchBaseQuery) => {
        const result = await fetchBaseQuery({
          url: `/issuers`,
          method: 'GET',
        });
        if (result.error?.status === 404) {
          // don't refetch on 404, 404 is a valid response to cache
          return { data: false };
        }
        return result;
      },
    }),
  }),
});

export default api;

export const { useTokensQuery, useIssuersQuery, useTokensByNetworkQuery } = api;

export const useSelectedToken = (skip = false) => {
  const id = store.getState().selected.selectedTokenId;
  return api.useTokensQuery(undefined, {
    selectFromResult: ({ data, error, ...isStates }) => ({
      ...isStates,
      error,
      selectedToken: data?.find(token => token.id === id) ?? (data ? data[0] : undefined),
    }),
    skip: !!skip,
  });
};
