import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';
import Amplify, { Auth } from 'aws-amplify';
import tokenTrader from './tokenTrader';
import { NOMFA, SOFTWARE_TOKEN_MFA, TOTP } from '../components/MultiFactorAuthentication/constants';
import rootStore, { resetStores } from '.';
import history from 'app/history';

const COGNITO_REFRESH = 5 * 60 * 1000; // refresh cognito 5m before token expires
const TT_REFRESH = 4 * 60 * 1000; // refresh token trader 4m before token expires
const IDLE_TIMEOUT = 30 * 60 * 1000; // 30m idle timeout

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
});

let mfaUser, changePasswordPersistance;
let tokenTraderRefreshTimeout, cognitoRefreshTimeout;
let idleTimeout;

export const getMFAUser = () => mfaUser;

export const signIn = createAsyncThunk(
  'aws/signIn',
  async ({ username, password }, { dispatch }) => {
    mfaUser = await Auth.signIn(username, password);
    if (mfaUser.challengeName) {
      changePasswordPersistance = mfaUser;
      return mfaUser; // user needs to answer challenge
    }
    await dispatch(completeSignIn()).unwrap();
    await dispatch(refreshTokenTraderToken()).unwrap();
    return mfaUser;
  },
);

export const userInfo = createAsyncThunk('aws/userInfo', async () => await Auth.currentUserInfo());

export const confirmSignIn = createAsyncThunk(
  'aws/confirmSignIn',
  async ({ code }, { dispatch }) => {
    const user = getMFAUser();
    const response = await Auth.confirmSignIn(user, code, SOFTWARE_TOKEN_MFA);
    await dispatch(completeSignIn()).unwrap();
    await dispatch(refreshTokenTraderToken()).unwrap();
    return response;
  },
);

export const completeSignIn = createAsyncThunk(
  'aws/completeSignIn',
  async (_params, { dispatch }) => {
    const session = await Auth.currentSession();
    const cognitoJWT = session.getIdToken().getJwtToken();
    const expiry = session.getAccessToken().getExpiration();
    dispatch(userInfo());

    try {
      await dispatch(tokenTrader.endpoints.account.initiate({ token: cognitoJWT })).unwrap();
    } catch (e) {
      // fetch account throws if no account, create one
      await dispatch(tokenTrader.endpoints.createAccount.initiate({ token: cognitoJWT })).unwrap();
      await dispatch(tokenTrader.endpoints.account.initiate({ token: cognitoJWT })).unwrap();
    }

    const refreshIn = expiry * 1000 - Date.now() - COGNITO_REFRESH;
    cognitoRefreshTimeout = setTimeout(() => dispatch(refreshCognitoToken()), refreshIn);

    return {
      cognitoExpiresAt: expiry,
      cognitoJWT,
    };
  },
);

const refreshCognitoToken = createAsyncThunk('aws/refreshCognitoToken', async (_, { dispatch }) => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const session = await Auth.currentSession();

  try {
    return await new Promise(async (resolve, reject) => {
      cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
        if (err) return reject(err);
        const cognitoJWT = session.getIdToken().getJwtToken();
        const expiry = session.getAccessToken().getExpiration();
        const refreshIn = expiry * 1000 - Date.now() - COGNITO_REFRESH;
        cognitoRefreshTimeout = setTimeout(() => dispatch(refreshCognitoToken()), refreshIn);
        resolve({
          cognitoExpiresAt: expiry,
          cognitoJWT,
        });
      });
    });
  } catch (e) {
    signOut();
    history.push('/auth/signin');
    throw e;
  }
});

const refreshTokenTraderToken = createAsyncThunk(
  'aws/refreshTokenTraderToken',
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().aws.cognitoJWT;
      const ret = await dispatch(tokenTrader.endpoints.token.initiate({ token })).unwrap();
      const jwtData = JSON.parse(atob(ret.token.split('.')[1]));
      const refreshIn = jwtData.exp * 1000 - Date.now() - TT_REFRESH;
      tokenTraderRefreshTimeout = setTimeout(() => dispatch(refreshTokenTraderToken()), refreshIn);
      return ret;
    } catch (e) {
      signOut();
      history.push('/auth/signin');
      throw e;
    }
  },
);

export const removeMFA = createAsyncThunk('aws/removeMFA', async () => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.setPreferredMFA(user, NOMFA);
});

export const configureMFA = createAsyncThunk('aws/configureMFA', async ({ challengeAnswer }) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.verifyTotpToken(user, challengeAnswer);
  await Auth.setPreferredMFA(user, TOTP);
});

export const preferredMFA = createAsyncThunk('aws/preferredMFA', async () => {
  const user = await Auth.currentAuthenticatedUser();
  return await Auth.getPreferredMFA(user, {
    bypassCache: false,
  });
});

export const signUp = createAsyncThunk('aws/signUp', async ({ username, password }) => {
  return await Auth.signUp({
    username,
    password,
    attributes: { website: window.location.hostname },
  });
});

export const confirmSignUp = createAsyncThunk('aws/confirmSignUp', async ({ username, code }) => {
  return await Auth.confirmSignUp(username, code);
});

export const resendSignUp = createAsyncThunk('aws/resendSignUp', async ({ username }) => {
  return await Auth.resendSignUp(username);
});

export const changePassword = createAsyncThunk(
  'aws/changePassword',
  async ({ password }, { dispatch }) => {
    if (!changePasswordPersistance)
      throw new Error('Tried to change password but persistance state was missing');
    await Auth.completeNewPassword(changePasswordPersistance, password);
    changePasswordPersistance = null;
    return dispatch(completeSignIn()).unwrap();
  },
);

export const forgotPassword = createAsyncThunk('aws/forgotPassword', async ({ username }) => {
  return await Auth.forgotPassword(username);
});

export const forgotPasswordConfirm = createAsyncThunk(
  'aws/forgotPasswordConfirm',
  async ({ username, code, password }) => {
    return await Auth.forgotPasswordSubmit(username, code, password);
  },
);

const store = createSlice({
  name: 'aws',
  initialState: {
    // token trader
    jwt: null,
    issuedAt: null,
    expiresAt: null,

    // cognito
    userInfo: null,
    cognitoJWT: null,
    cognitoExpiresAt: null,
  },
  reducers: {
    signOut(state) {
      Auth.signOut();
      if (window.productFruits?.services) {
        window.productFruits.services.destroy();
      }
      state.jwt = null;
      state.issuedAt = null;
      state.expiresAt = null;
      state.userInfo = null;
      state.cognitoExpiresAt = null;
      state.cognitoJWT = null;
      clearTimeout(cognitoRefreshTimeout);
      clearTimeout(tokenTraderRefreshTimeout);
      clearTimeout(idleTimeout);
    },
    reset(state) {
      Auth.signOut();
      if (window.productFruits?.services) {
        window.productFruits.services.destroy();
      }
      state.jwt = null;
      state.issuedAt = null;
      state.expiresAt = null;
      state.userInfo = null;
      state.cognitoExpiresAt = null;
      state.cognitoJWT = null;
      clearTimeout(cognitoRefreshTimeout);
      clearTimeout(tokenTraderRefreshTimeout);
      clearTimeout(idleTimeout);
    },
  },
  extraReducers: builder => {
    builder.addCase(refreshTokenTraderToken.fulfilled, (state, { payload }) => {
      state.jwt = payload.token;
      state.issuedAt = payload.issuedAt;
      state.expiresAt = payload.expiresIn;
    });
    builder.addCase(completeSignIn.fulfilled, (state, { payload }) => {
      state.cognitoExpiresAt = payload.cognitoExpiresAt;
      state.cognitoJWT = payload.cognitoJWT;
    });
    builder.addCase(refreshCognitoToken.fulfilled, (state, { payload }) => {
      state.cognitoExpiresAt = payload.cognitoExpiresAt;
      state.cognitoJWT = payload.cognitoJWT;
    });
    builder.addCase(userInfo.fulfilled, (state, { payload }) => {
      state.userInfo = payload;
    });
    builder.addCase(preferredMFA.fulfilled, (state, { payload }) => {
      state.userInfo.preferredMFA = payload;
    });
    builder.addCase(REHYDRATE, (_state, { payload }) => {
      // this function runs once for each rehydrated store, but with the payload being the state being rehydrated
      (async () => {
        // we can't make addCase async, so we use an anonymous function
        // we're not supposed to dispatch from here, but there's no other way to hook from hydrated event
        if (payload && payload.cognitoExpiresAt) {
          const refreshCognitoIn = payload.cognitoExpiresAt * 1000 - Date.now() - COGNITO_REFRESH;
          if (refreshCognitoIn <= 0) {
            await rootStore.dispatch(refreshCognitoToken()).unwrap();
          } else {
            cognitoRefreshTimeout = setTimeout(
              () => rootStore.dispatch(refreshCognitoToken()),
              refreshCognitoIn,
            );
          }
        }
        if (payload && payload.expiresAt) {
          const refreshIn = payload.expiresAt * 1000 - Date.now() - TT_REFRESH;
          if (refreshIn <= 0) {
            await rootStore.dispatch(refreshTokenTraderToken()).unwrap();
          } else {
            tokenTraderRefreshTimeout = setTimeout(
              () => rootStore.dispatch(refreshTokenTraderToken()),
              refreshIn,
            );
          }
        }
      })();
    });
  },
});

export default store;

export const signOut = () => {
  store.actions.signOut();
  resetStores();
};

export const selectUserInfo = state => state.aws.userInfo;

// idle timeout handler
window.addEventListener('visibilitychange', () => {
  if (window.document.visibilityState === 'hidden') {
    idleTimeout = setTimeout(signOut, IDLE_TIMEOUT);
  } else {
    clearTimeout(idleTimeout);
  }
});
