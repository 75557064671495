import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'cex',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING,
    prepareHeaders,
  }),
  tagTypes: ['cex'],
  endpoints: builder => ({
    allCEXs: builder.query({
      query: () => '/cex',
    }),
    CEXsByToken: builder.query({
      query: ({ tokenId }) => ({
        url: 'cex_token/cexs',
        params: { tokenId },
      }),
    }),
    tokensByCEX: builder.query({
      query: ({ cexId }) => ({
        url: 'cex_token/tokens',
        params: { cexId },
      }),
    }),
    addCEXLogo: builder.mutation({
      query: ({ id, logo }) => {
        const formData = new FormData();
        formData.append('file', logo);
        return {
          method: 'POST',
          url: `cex/${id}/logo`,
          body: formData,
        };
      },
    }),
    addCEX: builder.mutation({
      query: ({ name, email, url, country, wallet, blockchainNetwork }) => ({
        method: 'POST',
        url: 'cex',
        body: {
          name,
          email,
          url,
          wallet,
          country,
          blockchainNetwork,
        },
      }),
    }),
    editCEX: builder.mutation({
      query: ({ id, name, url, cexStatus }) => ({
        method: 'PATCH',
        url: `cex/${id}`,
        body: {
          name,
          url,
          cexStatus,
        },
      }),
    }),
    retryCEX: builder.mutation({
      query: ({ id }) => ({
        method: 'PUT',
        url: `cex/${id}/retry`,
      }),
    }),
    allowCEX: builder.mutation({
      query: ({ tokenId, cexId }) => ({
        method: 'POST',
        url: 'cex_token',
        body: {
          tokenId,
          cexId,
        },
      }),
    }),
    disallowCEX: builder.mutation({
      query: ({ tokenId, cexId }) => ({
        method: 'DELETE',
        url: `/cex_token/cex/${cexId}/token/${tokenId}`,
      }),
    }),
    depositWallets: builder.query({
      query: ({ tokenId, request }) => {
        const { filter, ...requestButFilter } = request;
        return {
          url: '/cex/deposit-wallets',
          params: {
            tokenId,
            ...requestButFilter,
            ...filter,
          },
        };
      },
    }),
    approveDepositWallets: builder.mutation({
      query: ({ tokenId, cexId, depositWallets }) => ({
        method: 'PUT',
        url: `/cex/${cexId}/token/${tokenId}/deposit-wallets/approve`,
        body: {
          depositWallets,
        },
      }),
    }),
    deleteDepositWallets: builder.mutation({
      query: ({ tokenId, cexId, depositWalletId }) => ({
        method: 'DELETE',
        url: `/cex/${cexId}/token/${tokenId}/deposit-wallets/${depositWalletId}`,
      }),
    }),
    rejectDepositWallets: builder.mutation({
      query: ({ tokenId, cexId, depositWallets }) => ({
        method: 'POST',
        url: `/cex/${cexId}/token/${tokenId}/deposit-wallets/reject`,
        body: {
          depositWallets,
          cexId,
          tokenId,
        },
      }),
    }),
    revokeDepositWallets: builder.mutation({
      query: ({ tokenId, cexId, depositWalletId }) => ({
        method: 'POST',
        url: `/cex/${cexId}/token/${tokenId}/deposit-wallets/${depositWalletId}/revoke`,
      }),
    }),
    depositWalletRequestsCount: builder.query({
      query: ({ tokenId }) => ({
        url: `/cex/deposit-wallets`,
        params: {
          tokenId,
          offset: 0,
          limit: 1,
          status: 'PENDING',
        },
      }),
    }),
  }),
});

export default api;

export const {
  useLazyAllCEXsQuery,
  useLazyCEXsByTokenQuery,
  useLazyTokensByCEXQuery,
  useAddCEXLogoMutation,
  useAddCEXMutation,
  useEditCEXMutation,
  useRetryCEXMutation,
  useAllowCEXMutation,
  useDisallowCEXMutation,
  useLazyDepositWalletsQuery,
  useApproveDepositWalletsMutation,
  useDeleteDepositWalletsMutation,
  useRejectDepositWalletsMutation,
  useRevokeDepositWalletsMutation,
  useDepositWalletRequestsCountQuery,
} = api;
