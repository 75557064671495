import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';
import { ethereumNetworkInfo } from '@tokenyico/design-system';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'multichain',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING,
    prepareHeaders,
  }),
  endpoints: builder => ({
    networks: builder.query({
      query: () => ({
        url: `/networks`,
      }),
      transformResponse: ({ networks }) =>
        networks.map(el => {
          const ethNet = ethereumNetworkInfo.get(el.id);
          return { label: el.id, value: el.id, logo: ethNet?.logo, name: ethNet?.name };
        }),
    }),
  }),
});

export default api;

export const { useLazyNetworksQuery, useNetworksQuery } = api;
