import { useEffect, useState } from 'react';

let onlinePromise,
  nowOnline = () => {},
  setOnlineStates = [];
const setupPromise = () => {
  if (navigator.onLine) {
    onlinePromise = undefined; // no promise needed
  } else {
    onlinePromise = new Promise(resolve => {
      nowOnline = resolve;
    });
  }
};
setupPromise();

window.addEventListener('online', () => {
  nowOnline();
  setOnlineStates.map(state => state(true));
});

window.addEventListener('offline', () => {
  setupPromise();
  setOnlineStates.map(state => state(false));
});

// awaitable promise
export const untilOnline = () => onlinePromise;

// react hook
export const useOnlineState = () => {
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    let idx = setOnlineStates.length;
    setOnlineStates.push(setOnline);
    return () => {
      setOnlineStates.splice(idx, 1);
    };
  }, [setOnline]);

  return online;
};
