import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'auth',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/auth/',
    prepareHeaders,
  }),
  endpoints: builder => ({
    requestAccess: builder.mutation({
      query: body => ({
        method: 'POST',
        url: `/requestaccess`,
        body,
      }),
    }),
  }),
});

export default api;

export const { useRequestAccessMutation } = api;
