import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';
import { omit, omitBy, get } from 'lodash';
import store from './';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'position-reports',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/tokens/',
    prepareHeaders,
  }),
  tagTypes: ['position-reports'],
  endpoints: builder => ({
    positionReports: builder.query({
      query: ({ tokenId, ...query }) => {
        const cleanQuery = omitBy(query, value => !value);
        const filter = get(cleanQuery, 'filter');
        return {
          url: `/${tokenId}/position-reports`,
          params: {
            ...omit(cleanQuery, 'filter'),
            ...filter,
          },
        };
      },
    }),
    positionReportById: builder.query({
      query: ({ tokenId, id, ...query }) => {
        const cleanQuery = omitBy(query, value => !value);
        const filter = get(cleanQuery, 'filter');
        return {
          url: `${tokenId}/position-reports/${id}`,
          params: {
            ...omit(cleanQuery, 'filter'),
            ...filter,
          },
        };
      },
    }),
    createPositionReport: builder.mutation({
      query: ({ tokenId, name, reportDate }) => ({
        method: 'POST',
        url: `${tokenId}/position-reports`,
        body: { name, reportDate },
      }),
    }),
  }),
});

export default api;

export const {
  useLazyPositionReportsQuery,
  useLazyPositionReportByIdQuery,
  useCreatePositionReportMutation,
} = api;

export const getPositionReportCSVURL = async ({ tokenId, id }) => {
  const queryString = new URLSearchParams({
    jwt: store.getState().aws.jwt,
  });

  const url = new URL(
    `${REACT_APP_API_URL_SERVICING}/tokens/${tokenId}/position-reports/${id}/csv`,
  );
  url.search = queryString;

  return url.toString();
};
