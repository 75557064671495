import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';
import { omitBy, omit } from 'lodash';
import tokens from './tokens';
import store from './';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'tokenHolders',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/tokens/',
    prepareHeaders,
  }),
  endpoints: builder => ({
    tokenHolders: builder.query({
      query: ({ tokenId, ...params }) => ({
        url: `${tokenId}/holders`,
        params,
      }),
    }),
    tokenHolderById: builder.query({
      query: ({ tokenId, holderId }) => ({
        url: `${tokenId}/holders/${holderId}`,
      }),
    }),
    whitelistTokenHolders: builder.mutation({
      query: ({ tokenId, ...body }) => ({
        method: 'POST',
        url: `${tokenId}/holders/whitelist`,
        body: {
          ...body, // {identityIds, wallets, holderIds}
        },
      }),
      onQueryStarted: async ({ tokenId }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(tokens.util.invalidateTags([{ type: 'investorRequestsCount', id: tokenId }]));
      },
    }),
    revokeTokenHolder: builder.mutation({
      query: ({ tokenId, holderId }) => ({
        method: 'POST',
        url: `${tokenId}/holders/${holderId}/revoke`,
      }),
      onQueryStarted: async ({ tokenId }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(tokens.util.invalidateTags([{ type: 'investorRequestsCount', id: tokenId }]));
      },
    }),
  }),
});

export default api;

export const {
  useLazyTokenHoldersQuery,
  useLazyTokenHolderByIdQuery,
  useWhitelistTokenHoldersMutation,
  useRevokeTokenHolderMutation,
} = api;

export const getTokenHolderCSVURL = async ({ tokenId, ...query }) => {
  const cleanQuery = omitBy(query, value => !value);
  const filter = cleanQuery.filter;

  const queryString = new URLSearchParams({
    ...omit(cleanQuery, ['filter', 'offset', 'limit']),
    ...filter,
    jwt: store.getState().aws.jwt,
  });

  const url = new URL(`${REACT_APP_API_URL_SERVICING}/tokens/${tokenId}/holders/csv`);
  url.search = queryString;

  return url.toString();
};
