import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'accounts',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/accounts/',
    prepareHeaders,
  }),
  endpoints: builder => ({
    accountMe: builder.query({
      query: () => ({
        url: `/me`,
      }),
    }),
  }),
});

export default api;

export const { useAccountMeQuery } = api;
