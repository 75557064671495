import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'workflows',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/workflows/',
    prepareHeaders,
  }),
  tagTypes: ['workflow'],
  endpoints: builder => ({
    workflows: builder.query({
      query: ({ tokenId, type, counterpartAddresses, externalId, status, limit, offset }) => ({
        url: '',
        params: {
          tokenId,
          type,
          counterpartAddresses,
          externalId,
          status,
          limit,
          offset,
        },
      }),
      providesTags: (_result, _error, { tokenId }) => [{ type: 'workflow', id: tokenId }],
    }),
  }),
});

export default api;

export const { useWorkflowsQuery } = api;
