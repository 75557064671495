//operations
export const UPDATE_MFA = 'UPDATE_MFA';
export const ENABLE_MFA = 'ENABLE_MFA';

//MFA Types
export const TOTP = 'TOTP';
export const NOMFA = 'NOMFA';

//Challenge Name
export const SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA';
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
