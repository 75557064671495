import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';
import { omit, omitBy, get } from 'lodash';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'billboard',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/billboard/',
    prepareHeaders,
  }),
  tagTypes: ['marketplaceBillboard'],
  endpoints: builder => ({
    marketplaceSettings: builder.query({
      query: ({ marketplaceId }) => `/settings/marketplace/${marketplaceId}`,
      providesTags: (_result, _error, { marketplaceId }) => [
        { type: 'marketplaceBillboard', id: marketplaceId },
      ],
    }),
    updateMarketplaceSettings: builder.mutation({
      query: ({ marketplaceId, ...body }) => ({
        method: 'POST',
        url: `/settings/marketplace/${marketplaceId}`,
        body,
      }),
      invalidatesTags: (_result, _error, { marketplaceId }) => [
        { type: 'marketplaceBillboard', id: marketplaceId },
      ],
    }),
    allOffers: builder.query({
      query: ({ marketplaceId, ...query }) => {
        const cleanQuery = omitBy(query, value => !value);
        const filter = get(cleanQuery, 'filter', {});
        return {
          url: `offers/marketplace/${marketplaceId}/all`,
          params: {
            ...omit(cleanQuery, 'filter'),
            ...filter,
          },
        };
      },
    }),
    offerById: builder.query({
      query: ({ id }) => `offers/${id}/`,
    }),
    offerContact: builder.query({
      query: ({ id }) => `offers/${id}/contact`,
    }),
    activateOffer: builder.mutation({
      query: ({ id }) => ({
        method: 'POST',
        url: `offers/${id}/activate`,
      }),
    }),
    deactivateOffer: builder.mutation({
      query: ({ id }) => ({
        method: 'POST',
        url: `offers/${id}/deactivate`,
      }),
    }),
    tokenSettings: builder.query({
      query: ({ tokenId }) => `settings/token/${tokenId}`,
      providesTags: (_result, _error, { marketplaceId }) => [
        { type: 'marketplaceBillboard', id: marketplaceId },
      ],
    }),
    updateTokenSettings: builder.mutation({
      query: ({ tokenId, ...body }) => ({
        method: 'POST',
        url: `settings/token/${tokenId}`,
        body,
      }),
      invalidatesTags: (_result, _error, { marketplaceId }) => [
        { type: 'marketplaceBillboard', id: marketplaceId },
      ],
    }),
    allOffersByToken: builder.query({
      query: ({ tokenId, ...query }) => {
        const cleanQuery = omitBy(query, value => !value);
        const filter = get(cleanQuery, 'filter', {});
        return {
          url: `offers/token/${tokenId}/all`,
          params: {
            ...omit(cleanQuery, 'filter'),
            ...filter,
          },
        };
      },
    }),
  }),
});

export default api;

export const {
  useMarketplaceSettingsQuery,
  useUpdateMarketplaceSettingsMutation,
  useLazyAllOffersQuery,
  useLazyOfferByIdQuery,
  useLazyOfferContactQuery,
  useActivateOfferMutation,
  useDeactivateOfferMutation,
  useTokenSettingsQuery,
  useUpdateTokenSettingsMutation,
  useLazyAllOffersByTokenQuery,
} = api;
