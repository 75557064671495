import { toastError } from 'app/helpers/toast';
import { useEffect } from 'react';
import placeholderImage from 'assets/images/placeholder.png';
import { untilOnline } from 'app/helpers/online';

export const useErrorHandler = error => {
  useEffect(() => {
    if (error) toastError(error);
  }, [error]);
};

export const prepareHeaders = async (headers, { getState, endpoint }) => {
  await untilOnline();
  if (endpoint === 'strapiProjects') {
    // do not add auth header to strapi endpoint
    return headers;
  }

  const token = getState().aws.jwt;
  if (token) headers.set('Authorization', `Bearer ${token}`);
  return headers;
};

const isPrimitive = value =>
  value instanceof File ||
  value instanceof Date ||
  ['string', 'number', 'boolean'].includes(typeof value) ||
  value === null ||
  value === undefined;

export const makeFormData = (data, prefix = '', fd = new FormData()) => {
  if (Array.isArray(data)) {
    data.forEach((value, idx) => {
      if (isPrimitive(value)) {
        fd.append(prefix + '[' + idx + ']', value);
      } else {
        makeFormData(value, prefix + '[' + idx + ']', fd);
      }
    });
  } else if (typeof data === 'object') {
    Object.keys(data).forEach(key => {
      if (isPrimitive(data[key])) {
        fd.append(prefix ? prefix + '[' + key + ']' : key, data[key]);
      } else {
        makeFormData(data[key], prefix ? prefix + '[' + key + ']' : key, fd);
      }
    });
  }
  return fd;
};

export const sanitizeTokens = ({ account, response }) => {
  // const account = accounts.endpoints.accountMe.select()(store.getState()).data;
  let tokens = response;
  if (!account?.isAdmin) {
    tokens = tokens.filter(
      token => token.permissions.includes('AGENT') || token.permissions.includes('OWNER'),
    );
  }

  tokens = tokens.map(token => ({
    status: token.deploymentStatus,
    ...token,
    image: token.image ?? placeholderImage,
  }));

  return tokens;
};
