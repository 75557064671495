import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const { REACT_APP_API_URL_TOKEN_TRADER } = process.env;

const api = createApi({
  reducerPath: 'tokenTrader',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_TOKEN_TRADER,
  }),
  endpoints: builder => ({
    token: builder.query({
      query: ({ token }) => ({
        method: 'POST',
        url: `/token`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    account: builder.query({
      query: ({ token }) => ({
        url: `/shared-accounts/me`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    createAccount: builder.mutation({
      query: ({ token }) => ({
        method: 'POST',
        url: `/accounts`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export default api;

export const { useTokenQuery, useAccountQuery, useCreateAccountMutation } = api;
