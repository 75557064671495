import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';
import store from './';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'subscriptions',
  tagTypes: ['subscriptionOrders'],
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/subscriptions/',
    prepareHeaders,
  }),
  endpoints: builder => ({
    subscriptions: builder.query({
      query: ({ tokenId }) => ({
        url: `/tokens/${tokenId}`,
      }),
    }),
    subscriptionOrders: builder.query({
      query: ({ subscriptionId, tokenId, ...request }) => ({
        url: `${subscriptionId}/tokens/${tokenId}/investor-orders`,
        params: {
          ...request,
        },
      }),
      providesTags: (_result, _error, { subscriptionId }) => [
        { type: 'subscriptionOrders', id: subscriptionId },
      ],
    }),
    subscriptionOrder: builder.query({
      query: ({ subscriptionId, tokenId, orderId }) => ({
        url: `${subscriptionId}/tokens/${tokenId}/investor-orders/${orderId}`,
      }),
    }),
    exchangeRates: builder.query({
      query: ({ subscriptionId, tokenId }) => ({
        url: `${subscriptionId}/tokens/${tokenId}/exchange-rates`,
      }),
    }),
    subscriptionOrderConfirm: builder.mutation({
      query: ({
        subscriptionId,
        tokenId,
        orderId,
        confirmedPaymentAmount,
        exchangeRateType,
        exchangeRate,
      }) => ({
        method: 'POST',
        url: `${subscriptionId}/tokens/${tokenId}/investor-orders/${orderId}/confirm`,
        body: {
          confirmedPaymentAmount,
          exchangeRateType,
          exchangeRate,
        },
      }),
    }),
    subscriptionOrderConfirmMany: builder.mutation({
      query: ({ subscriptionId, tokenId, orderIds, action }) => ({
        method: 'POST',
        url: `${subscriptionId}/tokens/${tokenId}/investor-orders/${action}`,
        body: {
          orderIds,
        },
      }),
    }),
  }),
});

export default api;

export const {
  useSubscriptionsQuery,
  useLazySubscriptionOrdersQuery,
  useLazySubscriptionsQuery,
  useLazyExchangeRatesQuery,
  useSubscriptionOrderConfirmMutation,
  useSubscriptionOrderConfirmManyMutation,
} = api;

export const getSubscriptionOrderCSVURL = async ({ tokenId, subscriptionId }) => {
  const queryString = new URLSearchParams({
    jwt: store.getState().aws.jwt,
  });

  const url = new URL(
    `${REACT_APP_API_URL_SERVICING}/subscriptions/${subscriptionId}/tokens/${tokenId}/investor-orders/csv`,
  );
  url.search = queryString;

  return url.toString();
};
