import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';

const { REACT_APP_API_URL_SERVICING } = process.env;

const api = createApi({
  reducerPath: 'holders',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING,
    prepareHeaders,
  }),
  endpoints: builder => ({
    createHolders: builder.mutation({
      query: ({ tokenId, holders }) => ({
        method: 'POST',
        url: `/tokens/${tokenId}/import-investors`,
        body: { holdersArray: holders },
      }),
    }),
  }),
});

export default api;

export const { useCreateHoldersMutation } = api;
