import { createSlice } from '@reduxjs/toolkit';

const store = createSlice({
  name: 'selected',
  initialState: {
    selectedMarketplaceId: null,
    selectedTokenId: null,
  },
  reducers: {
    setSelectedMarketplace: (state, { payload }) => {
      state.selectedMarketplaceId = payload;
    },
    setSelectedTokenId: (state, { payload }) => {
      state.selectedTokenId = payload;
    },
    reset(state) {
      state.selectedMarketplaceId = null;
      state.selectedTokenId = null;
    },
  },
});

export default store;

export const { setSelectedMarketplace, setSelectedTokenId } = store.actions;
